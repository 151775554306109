/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext } from 'react';
import { IFirebaseGlobal } from '../types/firebase';
import { IGlobalToast } from '../types/toast';

export interface IStore {
  firebaseEmis: IFirebaseGlobal;
  globalToast: IGlobalToast;
}
export const StoreContext = createContext<IStore>({
  firebaseEmis: {} as IFirebaseGlobal,
  globalToast: {} as IGlobalToast
});

export function withContext() {
  return useContext(StoreContext);
}
