import React from 'react';
import { FaCircleNotch } from 'react-icons/fa';

interface IProps {
  fullPage?: boolean;
}

const LoadingPlaceholder: React.FC<IProps> = ({ fullPage }) => (
  <div className={`w-full block top-0 left-0 bg-white opacity-75 ${fullPage !== true ? 'h-main-container' : ''}`}>
    <span className="text-primary opacity-75 flex items-center justify-center w-full h-full">
      <FaCircleNotch size={100} className='animate-spin' />
    </span>
  </div>
);

export default LoadingPlaceholder;
